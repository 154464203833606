type SuccessRes<T> = {
	type: 'success';
	data: T;
};

type ErrorRes<E> = {
	type: 'error';
	error: E;
};

export type HandledResult<T, E extends Error = Error> = SuccessRes<T> | ErrorRes<E>;

export const asResult: <T>(cb: () => Promise<T>) => Promise<HandledResult<T>> = async (cb) => {
	try {
		return {
			type: 'success',
			data: await cb(),
		};
	} catch (e: unknown) {
		if (e instanceof Error) {
			return {
				type: 'error',
				error: e,
			};
		}
		throw e;
	}
};

export const asMap: <T, U>(res: HandledResult<T>, handler: (arg0: T) => U) => HandledResult<U> = (res, handler) => {
	if (res.type == 'error') {
		return res;
	} else {
		return {
			type: 'success',
			data: handler(res.data),
		};
	}
};
