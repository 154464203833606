<script lang="ts">
	import { css } from 'styled-system/css';
	import { handleSubmit } from '../functions/handleSubmit';

	export let isDark: boolean;

	const handleFunc = async () => {
		await handleSubmit(isDark);
	};
</script>

<div class={css({ display: 'grid', w: '2rem', h: '2rem' })}>
	<div class={css({ gridArea: '1/1', color: 'text' })}>
		<svg viewBox="0 0 24 24">
			{#if isDark}
				<use xlink:href="/icons/moon.svg#symbol" />
			{:else}
				<use xlink:href="/icons/sun.svg#symbol" />
			{/if}
		</svg>
	</div>
	<input
		class={css({
			gridArea: '1/1',
			borderRadius: '100%',
			cursor: 'pointer',
			opacity: '0',
			zIndex: '5',
		})}
		type="checkbox"
		bind:checked={isDark}
		on:change={handleFunc}
	/>
</div>
