import { asResult } from '$lib/features/shared/functions/result';
import { updateOnBrowser } from './theme';

export const handleSubmit = async (isDark: boolean) => {
	const res = await asResult<boolean>(() =>
		fetch('/theme', {
			method: 'PUT',
			headers: { 'content-type': 'application-json' },
			body: JSON.stringify({ isDark: !isDark }),
		})
			.then((res) => res.json())
			/* eslint-disable @typescript-eslint/no-unsafe-assignment*/
			/* eslint-disable @typescript-eslint/no-unsafe-member-access*/
			.then((d) => d.isDark as boolean)
	);
	if (res.type == 'error') {
		throw res.error;
	}

	updateOnBrowser(res.data);
};
